<template>
    <div id="homepage" class="homepage">
        <el-row>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'soBill' })" class="box" style="height: 70px;  position: relative">
                    <div class="left_icon el-icon-share" style="color: #f7c114"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.outbound_total`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.so.soCount }}</div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'soBill' })" class="box" style="height: 70px; position: relative">
                    <div class="left_icon el-icon-goods" style="color: #007b43"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.out_of_stock`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.so.so3Count }}</div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'soBill' })" class="box" style="height: 70px; position: relative">
                    <div class="left_icon el-icon-office-building" style="color: #c97586"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.to_be_picked_goods`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.so.so4Count }}</div>
                    </div>
                </div>
            </el-col>
            <!-- <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div
              class="left_icon el-icon-printer"
              style="color: #65318e"
          ></div>
          <div class="right">
            <div class="right1">已发运</div>
            <div class="right2">{{ this.count.so.so8Count }}</div>
          </div>
        </div>
      </el-col> -->
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'asnBill' })" class="box" style="height: 70px; position: relative">
                    <div class="left_icon el-icon-s-data" style="color: #9ba88d"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.total_number_of_inbound_orders`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.asn.asnCount }}</div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'asnBill' })" class="box" style="height: 70px; position: relative">
                    <div class="left_icon el-icon-goods" style="color: #007b43"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.pending_storage`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.asn.asn0Count }}</div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
                <div @click="$router.push({ name: 'toCellInventory' })" class="box" style="height: 70px; position: relative">
                    <div class="left_icon el-icon-goods" style="color: #007b43"></div>
                    <div class="right">
                        <div class="right1">{{ $t(`m.homepage.pending_listing`) }}</div>
                        <div v-loading="loading.up" class="right2">{{ this.count.up.upCount }}</div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                <div v-loading="loading.zxt" class="box">
                    <div class="box3" style="position: relative">
                        <!-- <div style="position: absolute; right: 50px; top: 15px">
                        <el-radio-group v-model="radio2" size="medium">
                            <el-radio-button label="0"
                                >七天</el-radio-button
                            >
                            <el-radio-button label="1"
                                >本月</el-radio-button
                            >
                            <el-radio-button label="2"
                                >本季</el-radio-button
                            >
                        </el-radio-group>
                    </div> -->
                        <div id="main" style="width: 98%; height: 280px; margin-top: 40px"></div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <div v-loading="loading.rate" class="box">
                    <el-row>
                        <!-- <el-col :span="12">
                        <div class="box3">
                            <dv-decoration-9
                                style="width: 100px; height: 100px"
                                >66%</dv-decoration-9
                            >
                            <div class="title">库存周转率</div>
                        </div>
                    </el-col> -->
                        <!-- {{ cellUseRate }} -->
                        <el-col :span="12">
                            <div class="box3">
                                <dv-water-level-pond v-if="cellUseRate.data[0] != 0" :config="cellUseRate" style="width: 100px; height: 100px" />
                                <div class="title">{{ $t(`m.homepage.location_utilization_rate`) }}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="box">
                    <el-row>
                        <el-col :span="24">
                            <div class="box3">
                                <div class="title2">{{ $t(`m.homepage.task_pending`) }}</div>
                                <div>
                                    <span class="taskAgentKey">{{  $t(`m.homepage.put_on_shelves`) }}</span>
                                    <span class="taskAgentValue" @click="$router.push({ name: 'toCellInventory' })" v-loading="loading.taskAgent">
                                        {{ taskAgent.ups }}
                                    </span>
                                </div>
                                <div>
                                    <span class="taskAgentKey">{{ $t(`m.homepage.picking_up_goods`) }}</span>
                                    <span class="taskAgentValue" @click="$router.push({ name: 'PickTask' })" v-loading="loading.taskAgent">
                                        {{ taskAgent.picks }}
                                    </span>
                                </div>
                                <div>
                                    <span class="taskAgentKey">{{ $t(`m.homepage.replenish`) }}</span>
                                    <span class="taskAgentValue" @click="$router.push({ name: 'cpfrTask' })" v-loading="loading.taskAgent">
                                        {{ taskAgent.cpfrs }}
                                    </span>
                                </div>

                                <!-- <el-row>
                      <el-col  :span="3"  ><div class="title3">补货</div> </el-col>
                      <el-col  :span="21" ><el-progress  :stroke-width="12" :percentage="100" status="success"></el-progress></el-col>
                      
                    </el-row> -->
                                <!-- <el-row>
                      <el-col  :span="3"  ><div class="title3">质检</div> </el-col>
                      <el-col  :span="21"  ><el-progress :stroke-width="12"  :percentage="100" status="warning"></el-progress></el-col>
                      
                    </el-row> -->
                                <!-- <el-row>
                      <el-col  :span="3"  ><div class="title3">集货</div> </el-col>
                      <el-col  :span="21"  ><el-progress :stroke-width="12" :percentage="50" status="exception"></el-progress></el-col>
                      
                    </el-row>  -->
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <div class="box" style="padding :5px 10px 10px 20px">
            <el-tabs type="card">
                <el-tab-pane :label="$t(`m.homepage.abnormal_storage`)">
                    <div class="tableBox none">
                        <el-table :data="exList" style="width: 100%">
                            <el-table-column type="index" width="30"></el-table-column>
                            <el-table-column show-overflow-tooltip prop="itemName" :label="$t(`m.homepage.thumbnail`) +  '/' + $t(`m.homepage.product_code`) + '/' + $t(`m.common.product_name`)" min-width="300">
                                <template slot-scope="scope">
                                    <el-row :gutter="20">
                                        <el-col :span="8">
                                            <div style="display: inline-block;margin-left:5px;  width:80px;height:80px">
                                                <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"></el-image>
                                            </div>
                                        </el-col>
                                        <el-col :span="16">
                                            <div style="display: inline-block;margin-left:5px;  ">
                                                <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                                                <br />
                                                <span>{{ scope.row.itemName }}</span>
                                                <br />

                                                <span v-if="scope.row.shipBillNo != null">{{ scope.row.shipBillNo }}</span>
                                                <br />

                                                <span>{{ scope.row.billNo }}</span>
                                                <br />
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="billNo" :label="$t(`m.homepage.abnormal_information`)" min-width="230">
                                <template slot-scope="scope">
                                    <el-row :gutter="20">
                                        <el-col :span="12">
                                            <div style="display: inline-block;margin-left:5px;  width:80px;height:80px">
                                                <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrls[0]" :preview-src-list="scope.row.imgUrls"></el-image>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <span>类型:</span>
                                            <span class="textBtn" v-if="scope.row.type == 1">包裹异常</span>
                                            <span class="textBtn" v-if="scope.row.type == 2">物料异常</span>

                                            <br />
                                            <span>原因:</span>
                                            <span class="textBtn" v-if="scope.row.reason == 1">多发</span>
                                            <span class="textBtn" v-if="scope.row.reason == 2">少发</span>
                                            <span class="textBtn" v-if="scope.row.reason == 3">错发</span>
                                            <span class="textBtn" v-if="scope.row.reason == 4">破损</span>
                                            <span class="textBtn" v-if="scope.row.reason == 5">其他</span>

                                            <br />
                                            <span>描述:</span>
                                            <span class="textBtn">{{ scope.row.memo }}</span>
                                            <br />
                                        </el-col>
                                    </el-row>
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="billNo" :label="$t(`m.homepage.processing_scheme`)" min-width="180">
                                <template slot-scope="scope">
                                    <el-tag type="warning" v-if="scope.row.fangan == 1">全部退件</el-tag>
                                    <el-tag type="danger" v-if="scope.row.fangan == 2">全部弃件</el-tag>
                                    <el-tag type="success" v-if="scope.row.fangan == 3">全部入库</el-tag>
                                    <el-tag type="warning" v-if="scope.row.fangan == 4">部分入库部分退件</el-tag>
                                    <el-tag type="danger" v-if="scope.row.fangan == 5">部分入库部分弃件</el-tag>
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="expectQuantity" :label="$t(`m.homepage.quantity`)" min-width="130">
                                <template slot-scope="scope">
                                    订单数：
                                    <span style="color:#409EFF;margin-left:2px;font-size:20px">{{ scope.row.orderQuantity / 1 }}</span>
                                    <br />
                                    已入库数：
                                    <span style="color:#409EFF;margin-left:2px;font-size:20px">{{ scope.row.inQuantity / 1 }}</span>
                                    <br />
                                    异常数：
                                    <span style="color:red;margin-left:2px;font-size:20px">{{ scope.row.quantity / 1 }}</span>
                                    <br />

                                    <br />
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="toQuantity" :label="$t(`m.homepage.acceptance_information`) + '/' + $t(`m.homepage.abnormal_information`)" min-width="200">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.state == 0" type="success">初始化</el-tag>
                                    <el-tag v-if="scope.row.state == 1" type="success">提交ERP</el-tag>
                                    <el-tag v-if="scope.row.state == 2" type="danger">待处理</el-tag>
                                    <el-tag v-if="scope.row.state == 3" type="success">已经处理</el-tag>

                                    <div style="width:90%" v-if="scope.row.state == 2">
                                        入库数量：
                                        <el-input-number
                                            :controls="controls"
                                            @focus="input0Set"
                                            v-model="scope.row.toQuantity"
                                            controls-position="right"
                                            min="0"
                                            placeholder=""
                                            label="请输入数量"
                                            size="small"
                                            style="width: 90%;"
                                        ></el-input-number>

                                        <div style="height:5px"></div>

                                        打印数量：
                                        <el-input-number
                                            :controls="controls"
                                            @focus="input0Set"
                                            v-model="scope.row.printQuantity"
                                            controls-position="right"
                                            min="0"
                                            label="请输入数量"
                                            size="small"
                                            style="width: 90%;"
                                        ></el-input-number>
                                        <div style="height:5px"></div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- @current-change="exCurrentChange" -->
                        <el-pagination
                            class="pagination-bottom"
                            style="text-align: right;"
                            :current-page.sync="exSearchData.pageNum"
                            :page-size="exSearchData.pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="exSearchData.total"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t(`m.homepage.inventory_awaiting_listing`)">
                    <div>
                        <el-table highlight-current-row :data="upList" style="width: 100%">
                            <el-table-column fixed="left" show-overflow-tooltip prop="inventoryId" label="库存ID" min-width="100"></el-table-column>
                            <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150">
                                <template slot-scope="scope">
                                    <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130">
                                <template slot-scope="scope">
                                    <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemCode }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="organizationName" label="工厂" min-width="130">
                                <template slot-scope="scope">
                                    <span class="textBtn" @click="$parent.showPublicModal(scope.row.organizationId, 'organization')">{{ scope.row.organizationName }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="batchName" label="批次" min-width="130"></el-table-column>
                            <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="140"></el-table-column>

                            <el-table-column show-overflow-tooltip prop="allotQuantity" label="分配数量" min-width="140">
                                <template slot-scope="scope">
                                    <span style="color:black;font-weight:900;">{{ scope.row.allotQuantity }}</span>
                                    <span v-if="scope.row.allotQuantity > 0" style="font-size:20% ;color:red ">爆</span>
                                </template>
                            </el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="cellCode" label="货位" min-width="150"></el-table-column>
                            <el-table-column sortable show-overflow-tooltip prop="boxCode" label="托盘号" min-width="150"></el-table-column>
                        </el-table>
                        <el-pagination
                            class="pagination-bottom"
                            style="text-align: right;"
                            @current-change="upCurrentChange"
                            @size-change="upSizeChange"
                            :current-page.sync="upSearchData.pageNum"
                            :page-sizes="[15, 30, 50, 100]"
                            :page-size="upSearchData.pageSize"
                            layout="sizes,total, prev, pager, next"
                            :total="upSearchData.total"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t(`m.homepage.waiting_to_pick_waves`)">
                    <div class="tableBox  ">
                        <el-table :data="waveMasters" style="width: 100%">
                            <el-table-column sortable prop="code" label="波次编码">
                                <template slot-scope="scope">
                                    <div slot="header" class="clearfix">
                                        <span>{{ scope.row.waveId }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="生成时间" min-width="150"></el-table-column>
                            <el-table-column prop="totalBillCount" label="订单总数" min-width="150"></el-table-column>
                            <el-table-column prop="totalSkuCount" label="sku总数" min-width="150"></el-table-column>
                            <el-table-column prop="waveTacticName" label="波次策略" min-width="150">
                                <template slot-scope="scope">
                                    {{ scope.row.waveTacticName }}
                                    <span v-if="scope.row.soStructure == 1">（一单一品）</span>
                                    <span v-if="scope.row.soStructure == 2">（ 一单多品）</span>
                                    <span v-if="scope.row.soStructure == 3">（ 一单一品一件）</span>
                                    <span v-if="scope.row.soStructure == 4">（一单一品多件）</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="isSeeding" label="分拣方式：" min-width="150">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.isSeeding == 1">先拣后分</span>
                                    <span v-if="scope.row.isSeeding == 0">边拣边分</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="state" label="状态" min-width="150">
                                <template slot-scope="scope">
                                    <span style="color:#F56C6C" v-if="scope.row.state == 0">订单接收中</span>
                                    <span style="color:#8B3A62" v-if="scope.row.state == 1">待拣货</span>
                                    <span style="color:#8B3A62" v-if="scope.row.state == 11">拣货中</span>
                                    <span style="color:#543044" v-if="scope.row.state == 2">拣货完成</span>
                                    <span style="color:#E6A23C" v-if="scope.row.state == 3">播种完成</span>
                                    <span style="color:#67C23A" v-if="scope.row.state == 4">发运完成</span>
                                    <span style="color:#67C23A" v-if="scope.row.state == 5">结束</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            style="text-align: right;"
                            class="pagination-bottom"
                            @current-change="waveCurrentChange"
                            :current-page.sync="waveSearchData.pageNum"
                            :page-size="waveSearchData.pageSize"
                            @size-change="waveSizeChange"
                            :page-sizes="[15, 30, 50, 100]"
                            layout="sizes,total, prev, pager, next  "
                            :total="waveSearchData.total"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t(`m.homepage.return_the_goods_to_the_shelves`)">
                    <div>
                        <el-table :data="pickTaskReturns" style="width: 100%">
                            <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150"></el-table-column>
                            <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130"></el-table-column>

                            <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="70">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.quantity }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip prop="boxCode" label="boxCode" min-width="70"></el-table-column>
                            <el-table-column show-overflow-tooltip prop="fromInventoryId" label="fromInventoryId" min-width="70"></el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="toCellCode" label="推荐货位" min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.toCellCode }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="state" label="状态" min-width="80">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state == 0" style="color:#E6A23C">待上架</span>
                                    <span v-if="scope.row.state == 1" style="color:#67C23A">已完成</span>
                                </template>
                            </el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="type" label="类型" min-width="80">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == 1" style="color:#909399">配货异常</span>
                                    <span v-if="scope.row.type == 2" style="color:#F56C6C">订单修改（未发运）</span>
                                    <span v-if="scope.row.type == 3" style="color:#F56C6C">订单修改（已发运）</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            class="pagination-bottom"
                            style="text-align: right;"
                            @current-change="returnCurrentChange"
                            :current-page.sync="returnSearchData.pageNum"
                            :page-size="returnSearchData.pageSize"
                            @size-change="returnSizeChange"
                            :page-sizes="[15, 30, 50, 100]"
                            layout="sizes,total, prev, pager, next, jumper"
                            :total="returnSearchData.total"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t(`m.homepage.suggestions_for_restocking`)">
                    <div>
                        <el-table v-loading="loading.cprfTable" highlight-current-row :data="cpfrTasks" style="width: 100%">
                            <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150">
                                <template slot-scope="scope">
                                    <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130">
                                <template slot-scope="scope">
                                    <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemCode }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="70">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.quantity }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip prop="createTime" :label="$t(`m.common.creation_time`)" min-width="100"></el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="fromCellCode" label="FROM货位" min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.fromCellCode }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column sortable show-overflow-tooltip prop="toCellCode" label="TO货位" min-width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.toCellCode }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column sortable show-overflow-tooltip prop="type" label="来源" min-width="80">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type == 1">配置驱动</span>
                                    <span v-else-if="scope.row.type == 2">订单驱动</span>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-pagination
                            class="pagination-bottom"
                            style="text-align: right;"
                            @current-change="cpfrCurrentChange"
                            :current-page.sync="cpfrSearchData.pageNum"
                            :page-size="cpfrSearchData.pageSize"
                            @size-change="cpfrSizeChange"
                            :page-sizes="[15, 30, 50, 100]"
                            layout="sizes,total, prev, pager, next, jumper"
                            :total="cpfrSearchData.total"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import print from 'print-js';
import { mapState } from 'vuex';

var echarts = require('echarts');

export default {
    name: 'Homepage',
    data() {
        return {
            count: {
                so: {},
                asn: {},
                up: {},
                asnEx: {},
            },
            organizationCode: PF.getLocal('organizationCode'),

            wareInfos: [],
            loading: {
                cprfTable: false,
                zxt: false,
                top: false,
                taskAgent: false,
                rate: false,
            },
            taskAgent: {},
            //  cellUseRate:'',
            cpfrTasks: [],
            cpfrSearchData: {
                pageNum: 1,
                pageSize: 5,
                total: 0,
                state: 0,
                wareId: PF.getLocal('wareId', 'number'),
            },

            upList: [],
            upSearchData: {
                pageNum: 1,
                pageSize: 5,
                total: 0,
                state: 0,
                cellUseType: 0,
                wareId: PF.getLocal('wareId', 'number'),
            },

            exList: [],
            exSearchData: {
                pageNum: 1,
                pageSize: 5,
                total: 0,
                state: 0,
                wareId: PF.getLocal('wareId', 'number'),
            },
            pickTaskReturns: [],
            returnSearchData: {
                pageNum: 1,
                pageSize: 5,
                total: 0,
                state: 0,
                wareId: PF.getLocal('wareId', 'number'),
            },
            waveMasters: [],
            waveSearchData: {
                state: 1,
                pageNum: 1,
                pageSize: 10,
                total: null,

                wareId: PF.getLocal('wareId', 'number'),
            },
            soCount: 0,
            shippedCount: 0,
            unShippedCount: 0,
            acceptCount: 0,
            qcCount: 0,
            searchObj: {
                days: '7',
                wareId: PF.getLocal('wareId', 'number'),
                //   organizationCode:PF.getLocal('organizationCode' ),
            },

            radio2: 0,
            cellUseRate: {
                data: [0, 0],
                shape: 'roundRect',
            },
            config5: {
                value: 66,
                textColor: 'black',
            },

            option: {
                color: ['#0090FF', '#36CE9E', '#FFC005', '#FF515A', '#8B5CFF', '#00CA69'],
                title: {
                    text: this.$t(`m.homepage.operation_statistics`),
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: [this.$t(`m.homepage.check_and_accept`), this.$t(`m.homepage.put_on_shelves`), this.$t(`m.homepage.picking_up_goods`), this.$t(`m.homepage.moving_inventory`)],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                // toolbox: {
                //   feature: {
                //     saveAsImage: {},
                //   },
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        smooth: true,
                        name: this.$t(`m.homepage.check_and_accept`),
                        type: 'line',
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        smooth: true,
                        name: this.$t(`m.homepage.put_on_shelves`),
                        type: 'line',

                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                    {
                        smooth: true,
                        name: this.$t(`m.homepage.picking_up_goods`),
                        type: 'line',

                        data: [150, 232, 201, 154, 190, 330, 410],
                    },
                    {
                        smooth: true,
                        name: this.$t(`m.homepage.moving_inventory`),
                        type: 'line',

                        data: [320, 332, 301, 334, 390, 330, 320],
                    },
                    // {
                    //   smooth: true,
                    //   name: "补货",
                    //   type: "line",

                    //   data: [820, 932, 901, 934, 1290, 1330, 1320],
                    // },
                ],
            },

            // productList: this.$store.state.productList // 获取store.js > state > productList
        };
    },
    computed: {
        ...mapState(['productList']),
    },
    methods: {
        cpfrCurrentChange(val) {
            this.cpfrSearchData.pageNum = val;
            this.getPickTaskData();
        },
        cpfrSizeChange(val) {
            this.cpfrSearchData.pageSize = val;
            this.getPickTaskData();
        },
        getPickTaskData() {
            let that = this;
            that.loading.cpfrTable = true;
            IOT.getServerData('/cpfr/tasks/list', 'get', this.cpfrSearchData, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;

                    this.cpfrTasks = list;
                    this.cpfrSearchData.total = ret.total;
                    this.cpfrSearchData.pageNum = ret.pageNumber;
                    that.loading.cpfrTable = false;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        upCurrentChange(val) {
            this.upSearchData.pageNum = val;
            this.getUp();
        },
        upSizeChange(val) {
            this.upSearchData.pageSize = val;
            this.getUp();
        },
        getUp() {
            let that = this;
            let upSearchData = PF.JSON(this.upSearchData);
            IOT.getServerData('/inventorys/CellUseTypeList', 'get', upSearchData, ret => {
                that.loading.masterTable = false;
                if (ret.code === 200) {
                    this.upList = ret.rows;
                    this.upSearchData.total = ret.total;
                    this.upSearchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        returnCurrentChange(val) {
            this.returnSearchData.pageNum = val;
            this.getPickTaskReturn();
        },
        returnSizeChange(val) {
            this.returnSearchData.pageSize = val;
            this.getPickTaskReturn();
        },

        getPickTaskReturn() {
            let that = this;
            IOT.getServerData('/pick/task/returns/findList', 'get', this.returnSearchData, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;
                    this.pickTaskReturns = list;
                    this.returnSearchData.total = ret.total;
                    this.returnSearchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        waveCurrentChange(val) {
            this.waveSearchData.pageNum = val;
            this.getPickTaskReturn();
        },
        waveSizeChange(val) {
            this.waveSearchData.pageSize = val;
            this.getPickTaskReturn();
        },

        getWaveMasterData() {
            IOT.getServerData('/wave/masters/list', 'post', this.waveSearchData, ret => {
                this.loading.parent = false;
                if (ret.code === 200) {
                    this.waveMasters = ret.rows;

                    this.waveSearchData.total = ret.total;
                    this.waveSearchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        fetchData(val) {
            var that = this;
            let param = val ? val : this.searchObj.days;
            that.loading.up = true;
            IOT.getServerData(
                '/home/statistics/topStatistics',
                'post',
                this.searchObj,
                ret => {
                    that.loading.up = false;
                    if (ret.code === 200) {
                        this.count = ret.data;
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );

            that.loading.zxt = true;
            IOT.getServerData(
                '/home/statistics/countStatistics',
                'post',
                this.searchObj,
                ret => {
                    that.loading.zxt = false;
                    if (ret.code === 200) {
                        that.option.xAxis.data = ret.data.dates;
                        that.option.series[0].data = ret.data.accepts;
                        that.option.series[1].data = ret.data.ups;
                        that.option.series[2].data = ret.data.picks;
                        that.option.series[3].data = ret.data.moves;
                        that.initEcharts();
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );

            that.loading.taskAgent = true;
            IOT.getServerData(
                '/home/statistics/taskAgent',
                'post',
                this.searchObj,
                ret => {
                    that.loading.taskAgent = false;
                    if (ret.code === 200) {
                        that.taskAgent = ret.data;
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );

            that.loading.rate = true;
            IOT.getServerData(
                '/home/statistics/cellUseRate',
                'post',
                this.searchObj,
                ret => {
                    that.loading.rate = false;
                    if (ret.code === 200) {
                        that.cellUseRate.data[0] = ret.data;
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );
        },
        changeDays(val) {
            this.fetchData(val);
        },

        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        initEcharts() {
            var myChart = echarts.init(document.getElementById('main'));
            // 指定图表的配置项和数据
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(this.option);
        },
    },
    mounted() {},
    created() {
        this.fetchData();
        this.getPickTaskData();
        this.getUp();
        this.getPickTaskReturn();
        this.getWaveMasterData();
    },
  watch: {
      '$i18n.locale': {
        handler:async function () {
          if(document.getElementById('main')) {
            this.option = {...this.option,
              title: {
                text: this.$t(`m.homepage.operation_statistics`),
              },
              legend: {
                data: [this.$t(`m.homepage.check_and_accept`), this.$t(`m.homepage.put_on_shelves`), this.$t(`m.homepage.picking_up_goods`), this.$t(`m.homepage.moving_inventory`)],
              },
              series: [
                {
                  smooth: true,
                  name: this.$t(`m.homepage.check_and_accept`),
                  type: 'line',
                  data: [120, 132, 101, 134, 90, 230, 210],
                },
                {
                  smooth: true,
                  name: this.$t(`m.homepage.put_on_shelves`),
                  type: 'line',
                  data: [220, 182, 191, 234, 290, 330, 310],
                },
                {
                  smooth: true,
                  name: this.$t(`m.homepage.picking_up_goods`),
                  type: 'line',

                  data: [150, 232, 201, 154, 190, 330, 410],
                },
                {
                  smooth: true,
                  name: this.$t(`m.homepage.moving_inventory`),
                  type: 'line',
                  data: [320, 332, 301, 334, 390, 330, 320],
                },
              ],}
            await this.fetchData()
          }
        },
        immediate: true
      }
  }
};
</script>

<style scoped lang="less">
.homepage {
    height: 1900px;

    .el-loading-spinner .circular {
        height: 15px;
        width: 15px;
        -webkit-animation: loading-rotate 2s linear infinite;
        animation: loading-rotate 2s linear infinite;
    }

    .box {
        //   box-shadow: 0 2px 4px #c1deff, 0 0 6px #c1deff;

        border-radius: 10px;
        margin: 10px 15px 5px 8px;
        padding: 0px 20px 10px 0px;

        background-color: white;

        .left_icon {
            position: absolute;
            margin: 30px 20px;
        }

        .right {
            position: absolute;
            margin: 10px 10px;
            left: 45%;

            .right1 {
                font-size: 14px;
                margin: 0px 0px 10px 0px;
            }

            .right2 {
                font-size: 18px;
                font-weight: 700;
                margin: 5px 0px 10px 0px;
            }
        }

        .left_icon:before {
            font-size: 1cm;
        }
    }

    .box2 {
        margin: 10px 15px 0px 0px;
        padding: 10px 0px 0px 15px;
        border-radius: 5px;
        height: 80px;
    }

    .box3 {
        margin: 10px 0px 0px 15px;
        padding: 2px 0px 0px 15px;
        border-radius: 5px;

        .title {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 12px 0px 0px 25px;
            font-weight: 700;
        }

        .title2 {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 0px 0px 5px 0px;
            font-weight: 700;
            font-size: 20px;
        }

        .el-table .warning-row {
            background: oldlace;
        }

        .el-table .success-row {
            background: #f0f9eb;
        }

        .title3 {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 8px 0px 4px 0px;
            font-weight: 550;
            font-size: 10px;
        }

        .taskAgentKey {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 15px 0px 4px 0px;
            font-weight: 550;
            font-size: 15px;
        }

        .taskAgentValue {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            margin: 15px 0px 4px 30px;
            font-weight: 550;
            font-size: 20px;
            color: brown;
        }
    }

    .el-progress {
        width: 80%;
        line-height: 2;
        // display: inline;
    }

    .el-tabs--card {
        border: none;
    }
}
</style>
